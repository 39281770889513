import { Navigate, useLocation } from 'react-router-dom';
import { useAuthToken } from '../../../config/auth';

export const PrivateRoute = (props) => {
	const [getToken] = useAuthToken();
	const location = useLocation();

	if (!getToken){
		return <Navigate to={{
			pathname:'/login',
			search:`referrer=${encodeURIComponent(location.pathname+location.search)}`,
			
		}} />;
	}
	return props.children
}

export default PrivateRoute;