import { Fragment } from 'react';
import styles from './Badge.module.scss';
import SVG from 'react-inlinesvg';
import Tooltip from '../Tooltip/Tooltip';

const Badge = (props) => {
	const CustomTag = props.tooltip ? Tooltip : Fragment;
	const customProps = props.tooltip ? {text:props.tooltip} : {};

	function classNames(){
		const classNames = [styles.Badge];
		if (props.color){
			classNames.push(styles['Badge--'+props.color]);
		}
		if (props.rounded){
			classNames.push(styles['Badge--rounded-'+props.rounded]);
		}
		if (props.size){
			classNames.push(styles['Badge--'+props.size]);
		}
		if (!props.label && props.icon){
			classNames.push(styles['Badge--icon-only']);
		}
		if (props.position === 'absolute'){
			classNames.push(styles['Badge--absolute']);
		}
		return classNames.join(' ');
	}

	return (
		<CustomTag {...customProps}>
			<div className={classNames()}>
				<div className={styles.Body}>
					{props.icon && (props.iconPos === undefined || props.iconPos == 'before') && <div className={styles.Icon}><SVG src={process.env.PUBLIC_URL+'/img/icons/'+props.icon+'.svg'} /></div>}
					{props.label && <div className={styles.Label}>{props.label}</div>}
					{props.icon && props.iconPos == 'after' && <div className={styles.Icon}><SVG src={process.env.PUBLIC_URL+'/img/icons/'+props.icon+'.svg'} /></div>}
				</div>
			</div>
		</CustomTag>
	)
}

export default Badge;