import { Suspense, lazy, useState, useEffect } from 'react';
import { ApolloProvider, useQuery } from '@apollo/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/Organisms/Route/PrivateRoute';
import { ME_QUERY }  from './components/Queries/User';
import SiteError from './components/Molecules/Error/SiteError';
import SiteLoader from './components/Molecules/SiteLoader/SiteLoader';
import { useAppApolloClient } from './config/apolloClient';
import { useAuthToken } from './config/auth';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useLogoutMutation } from './components/Mutations/Auth';
import { ENV_QUERY } from './components/Queries/Env';
import Pusher from 'pusher-js';
import { useApolloClient } from '@apollo/client';

const Dashboard = lazy(() => import('./components/Pages/Dashboard'));
const Login = lazy(() => import('./components/Pages/Login'));
const RequestPassword = lazy(() => import('./components/Pages/RequestPassword'));
const ResetPassword = lazy(() => import('./components/Pages/ResetPassword'));
const Register = lazy(() => import('./components/Pages/Register'));
const SettingsUser = lazy(() => import('./components/Pages/Settings/User'));
/*
const More = lazy(() => import('./components/Pages/More'));
const Notifications = lazy(() => import('./components/Pages/Notifications'));
const Notification = lazy(() => import('./components/Pages/Notification'));
*/
const VerifyEmail = lazy(() => import('./components/Pages/VerifyEmail'));
const ChangeEmail = lazy(() => import('./components/Pages/ChangeEmail'));
const Breakdown = lazy(() => import('./components/Pages/Breakdown'));
const AdminUsers = lazy(() => import('./components/Pages/Admin/Users/Users'));
const AdminUsersEditData = lazy(() => import('./components/Pages/Admin/Users/EditData'));
const AdminUsersPermissions = lazy(() => import('./components/Pages/Admin/Users/Permissions'));
const AdminUsersOffers = lazy(() => import('./components/Pages/Admin/Users/Offers'));
const AdminUsersProcesses = lazy(() => import('./components/Pages/Admin/Users/Processes'));
const AdminEnergyNetworks = lazy(() => import('./components/Pages/Admin/EnergyNetworks/EnergyNetworks'));
const AdminProducts = lazy(() => import('./components/Pages/Admin/Products/Products'));
const MeteringPoints = lazy(() => import('./components/Pages/MeteringPoints'));
const MeteringPoint = lazy(() => import('./components/Pages/MeteringPoint'));
const Offers = lazy(() => import('./components/Pages/Offers'));
const OfferPublic = lazy(() => import('./components/Pages/OfferPublic'));
const Offer = lazy(() => import('./components/Pages/Offer'));
const ProvideRequiredData = lazy(() => import('./components/Pages/ProvideRequiredData'));
const AdminLeads = lazy(() => import('./components/Pages/Admin/Leads/Leads'));
const AcceptFriendInvitation = lazy(() => import('./components/Pages/AcceptFriendInvitation'));
const Friends = lazy(() => import('./components/Pages/Friends'));

function App(){
	const [isInit, setIsInit] = useState(false);

	i18n.use(LanguageDetector).use(initReactI18next).init({
	    detection:{
	        checkWhitelist:true
	    },
	    debug:false,
	    interpolation:{
	        escapeValue:false
	    }
	}, () => {
		setIsInit(true);
	});

	return isInit ? <Container i18n={i18n} /> : <SiteLoader />;
}

const Container = (props) => {
	const client = useAppApolloClient(props.i18n);

	return (
        <ApolloProvider client={client}>
	        <Wrapper i18n={props.i18n} />
	    </ApolloProvider>
    );
}

const Wrapper = (props) => {
	const client = useApolloClient();
	const [getToken] = useAuthToken();
	const { logout } = useLogoutMutation();
	const { data:dataEnv } = useQuery(ENV_QUERY);
	const { data:dataMe, loading:loadingMe, error:errorMe } = useQuery(ME_QUERY, {
		onCompleted:(data) => {
			if (!data.me && getToken){
				logout({
					update:() => {
						window.location.href = '/';
			        }
				});
			}
		}
	});

	useEffect(() => {
		if (dataMe && dataMe.me){
			const pusher = new Pusher('917f812c2514ad3add8b', {
				cluster:'eu'
			});
			const channel = pusher.subscribe(`user.${dataMe.me.id}`);
			channel.bind('new.energy.data', (data) => {
				client.cache.evict({ fieldName:'chartData' });
				client.cache.evict({ fieldName:'meteringPoints' });
				client.cache.evict({ fieldName:'meteringPoint' });
				client.cache.gc();
			});

			return function cleanup(){
				channel.unbind_all();
				channel.unsubscribe();
				pusher.disconnect();
			}
		}
	}, [dataMe]);

	useEffect(() => {
		if (dataEnv){
			props.i18n.addResourceBundle(props.i18n.language, ['translation'], dataEnv.env.translations);
		}
	}, [dataEnv]);

	const globalProps = {
        collapsed:true,
		hideSidebar:true,
		hideTopbar:false,
		additionalSidebar:false,
		mode:'container',
		background:false
    };

	return (
		<BrowserRouter>
			{loadingMe ?
				<SiteLoader />
			:
				<>
					{!errorMe ?
						<Suspense fallback={<SiteLoader />}>
							<Routes>
								<Route exact path="/" element={<PrivateRoute><Dashboard {...globalProps} background={true} /></PrivateRoute>} />
								<Route exact path="/login" element={<Login {...globalProps} />} />
								<Route exact path="/request-password" element={<RequestPassword {...globalProps} />} />
								<Route exact path="/reset-password" element={<ResetPassword {...globalProps} />} />
								<Route exact path="/register" element={<Register {...globalProps} />} />
								<Route exact path="/settings/user" element={<PrivateRoute><SettingsUser {...globalProps} /></PrivateRoute>} />
								{/*
								<Route exact path="/more" element={<PrivateRoute><More {...globalProps} /></PrivateRoute>} />
								<Route exact path="/notifications" element={<PrivateRoute><Notifications {...globalProps} /></PrivateRoute>} />
								<Route exact path="/notifications/:id/single" element={<PrivateRoute><Notification {...globalProps} /></PrivateRoute>} />
								*/}								
								<Route exact path="/verify-email" element={<VerifyEmail {...globalProps} />} />
								<Route exact path="/change-email" element={<ChangeEmail {...globalProps} />} />
								<Route exact path="/breakdown" element={<PrivateRoute><Breakdown {...globalProps} /></PrivateRoute>} />
								<Route exact path="/metering-points" element={<PrivateRoute><MeteringPoints {...globalProps} /></PrivateRoute>} />
								<Route exact path="/metering-points/:id" element={<PrivateRoute><MeteringPoint {...globalProps} /></PrivateRoute>} />
								<Route exact path="/offers" element={<PrivateRoute><Offers {...globalProps} /></PrivateRoute>} />
								<Route exact path="/offers/p/:token" element={<OfferPublic />} />
								<Route exact path="/offers/:id" element={<PrivateRoute><Offer {...globalProps} /></PrivateRoute>} />
								<Route exact path="/friends" element={<PrivateRoute><Friends {...globalProps} /></PrivateRoute>} />
								<Route exact path="/provide-required-data" element={<PrivateRoute><ProvideRequiredData {...globalProps} /></PrivateRoute>} />
								<Route exact path="/admin/users" element={<PrivateRoute><AdminUsers {...globalProps} /></PrivateRoute>} />
								<Route exact path="/admin/users/:id/edit-data" element={<PrivateRoute><AdminUsersEditData {...globalProps} /></PrivateRoute>} />
								<Route exact path="/admin/users/:id/permissions" element={<PrivateRoute><AdminUsersPermissions {...globalProps} /></PrivateRoute>} />
								<Route exact path="/admin/users/:id/offers" element={<PrivateRoute><AdminUsersOffers {...globalProps} /></PrivateRoute>} />
								<Route exact path="/admin/users/:id/processes" element={<PrivateRoute><AdminUsersProcesses {...globalProps} /></PrivateRoute>} />
								<Route exact path="/admin/energy-networks" element={<PrivateRoute><AdminEnergyNetworks {...globalProps} /></PrivateRoute>} />
								<Route exact path="/admin/products" element={<PrivateRoute><AdminProducts {...globalProps} /></PrivateRoute>} />
								<Route exact path="/admin/leads" element={<PrivateRoute><AdminLeads {...globalProps} /></PrivateRoute>} />
								<Route exact path="/accept-friend-invitation" element={<AcceptFriendInvitation {...globalProps} />} />
							</Routes>
						</Suspense>
					:
						<SiteError />
					}
				</>
			}
        </BrowserRouter>
	);
}

export default App;