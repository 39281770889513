import styles from './SiteLoader.module.scss';
import Spinner from '../../Atoms/Spinner/Spinner';

const SiteLoader = (props) => {
	return (
		<div className={styles.Wrapper}>
			<Spinner size="md" />
		</div>
	)
}

export default SiteLoader;