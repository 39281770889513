import styles from './Spinner.module.scss';

const Spinner = (props) => {
	function classNames(){
		const classNames = [styles.Spinner];
		if (props.color){
			classNames.push(styles['Spinner--'+props.color]);
		}
		if (props.size){
			classNames.push(styles['Spinner--'+props.size]);
		}
		if (props.center){
			classNames.push(styles['Spinner--center']);
		}
		if (props.space){
			classNames.push(styles['Spinner--space-'+props.space]);
		}
		return classNames.join(' ');
	}

	return (
		<div className={classNames()}></div>
	)
}

export default Spinner;