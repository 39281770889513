import _ from 'lodash';
import i18n from 'i18next';

export function randomString(length){
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++ ){
        result += characters.charAt(Math.floor(Math.random()*charactersLength));
    }
    return result;
}

export function parseField(item, value, valueModifier = null){
    if (typeof value === 'undefined' || !value) return false;
    if (value === true) return true;
    if (value.indexOf('if:') > -1){
        let arr = value.replace('if:', '').split(' ');
        let itemValue = valueModifier ? valueModifier(_.get(item, arr[0])) : _.get(item, arr[0]);
        var comparison = arr[2];
        switch(arr[2]){
            case 'null':
                comparison = null;
                break;
            case 'true':
                comparison = true;
                break;
            case 'false':
                comparison = false;
                break;
            default:
                comparison = arr[2];
                break;
        }
        if (arr[1] === '!=='){
            if (itemValue !== comparison){
                if (arr[3].indexOf('str:') > -1){
                    return arr[3].replace('str:', '');
                } else {
                    if (arr[3] === 'true') return true;
                    if (arr[3] === 'false') return false;
                    return valueModifier ? valueModifier(_.get(item, arr[3])) : _.get(item, arr[3]);
                }
            } else {
                if (arr[4].indexOf('str:') > -1){
                    return arr[4].replace('str:', '');
                } else {
                    if (arr[4] === 'true') return true;
                    if (arr[4] === 'false') return false;
                    return valueModifier ? valueModifier(_.get(item, arr[4])) : _.get(item, arr[4]);
                }
            }
        } else if (arr[1] === '<'){
            if ((Array.isArray(itemValue) ? itemValue.length : parseInt(itemValue)) < parseInt(comparison)){
                if (arr[3].indexOf('str:') > -1){
                    return arr[3].replace('str:', '');
                } else {
                    if (arr[3] === 'true') return true;
                    if (arr[3] === 'false') return false;
                    return valueModifier ? valueModifier(_.get(item, arr[3])) : _.get(item, arr[3]);
                }
            } else {
                if (arr[4].indexOf('str:') > -1){
                    return arr[4].replace('str:', '');
                } else {
                    if (arr[4] === 'true') return true;
                    if (arr[4] === 'false') return false;
                    return valueModifier ? valueModifier(_.get(item, arr[4])) : _.get(item, arr[4]);
                }
            }
        } else if (arr[1] === '>'){
            if ((Array.isArray(itemValue) ? itemValue.length : parseInt(itemValue)) > parseInt(comparison)){
                if (arr[3].indexOf('str:') > -1){
                    return arr[3].replace('str:', '');
                } else {
                    if (arr[3] === 'true') return true;
                    if (arr[3] === 'false') return false;
                    return valueModifier ? valueModifier(_.get(item, arr[3])) : _.get(item, arr[3]);
                }
            } else {
                if (arr[4].indexOf('str:') > -1){
                    return arr[4].replace('str:', '');
                } else {
                    if (arr[4] === 'true') return true;
                    if (arr[4] === 'false') return false;
                    return valueModifier ? valueModifier(_.get(item, arr[4])) : _.get(item, arr[4]);
                }
            }
        }
    } else {
        return valueModifier ? valueModifier(_.get(item, value)) : _.get(item, value);
    }
}

export function fixedContent(open){
    const site = document.getElementById('root').firstChild;
    const body = document.body;
    const currentScrollTop = window.scrollY;
    if (open && !body.classList.contains('site-fixed')){
        body.classList.add('site-fixed');
        site.style.marginTop = (currentScrollTop*-1)+'px';
        body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    } else if (!open && body.classList.contains('site-fixed')){
        const newScrollTop = parseInt(site.style.marginTop);
        site.removeAttribute('style');
        body.classList.remove('site-fixed');
        body.scrollTop = newScrollTop*-1;
        document.documentElement.scrollTop = newScrollTop*-1;
    }
}

export function getScrollParent(node){
    if (node == null){
        return null;
    }
    if (node.scrollHeight > node.clientHeight){
        return node;
    } else {
        return getScrollParent(node.parentNode);
    }
}

export function setNativeValue(element, value){
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;
    if (valueSetter && valueSetter !== prototypeValueSetter){
        prototypeValueSetter.call(element, value);
    } else {
        valueSetter.call(element, value);
    }
}

export function parseAxiosResponse(data, field, key){
    if (field === 'data'){
        if (data && data.data){
            if (key && data.data[key]){
                return data.data[key];
            } else {
                return data.data;
            }
        }
    }
    if (field === 'error'){
        if (data && data.data && data.data.success === false && data.data.error){
            return data.data.error;
        }
    }
    if (field === 'meta'){
        if (data && data.data && data.data.meta){
            return data.data.meta;
        }
    }
    return null;
}

export function humanFileSize(bytes, si = true, dp = 1) {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh){
        return bytes+' B';
    }
    const units = si ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    return bytes.toFixed(dp)+' '+units[u];
}

export function flattenObject(obj, parent, res = {}){
    for (const key of Object.keys(obj)){
        const propName = parent ? parent+'.'+key : key;
        if (typeof obj[key] === 'object'){
            flattenObject(obj[key], propName, res);
        } else {
            res[propName] = obj[key];
        }
    }
    return res;
}

export function elementInViewport(element){
    const bounding = element.getBoundingClientRect();
    if (bounding.top >= 0 && bounding.left >= 0 && bounding.right <= (window.innerWidth || document.documentElement.clientWidth) && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)){
        return true;
    } else {
        return false;
    }
}

export function strSlug(string, separator){
    if (typeof separator == 'undefined') separator = '-';
    var flip = separator == '-' ? '_' : '-';
    string = string.replace(flip, separator);
    string = string.toLowerCase().replace(new RegExp('[^a-z0-9'+separator+'\\s]', 'g'), '');
    string = string.replace(new RegExp('['+separator+'\\s]+', 'g'), separator);
    return string.replace(new RegExp('^['+separator+'\\s]+|['+separator+'\\s]+$', 'g'),'');
}

export function getColorByStatus(status){
    switch(status){
        case 'success':
            return 'green';
        case 'info':
            return 'blue';
        case 'warning':
            return 'orange';
        case 'error':
            return 'red';
        default:
            return 'green';
    }
}

export function getColorByLeadStatus(status){
    switch(status){
        case 'qualified':
            return 'gray';
        case 'intro':
            return 'blue';
        case 'demo':
            return 'purple';
        case 'proposal':
            return 'orange';
        case 'won':
            return 'green';
        default:
            return 'gray';
    }
}

export function getColorByFriendStatus(status){
    switch(status){
        case 'waiting':
            return 'gray';
        case 'confirmed':
            return 'blue';
        case 'won':
            return 'green';
        default:
            return 'gray';
    }
}

export function canSubmitOnboarding(profileProgress){
    return Object.entries(profileProgress).filter(item => item[0] !== 'consent' && (item[1].progress < 100 && !item[1].redundant)).length === 0;
}

export function toFloat(text){
    if (text){
        text = (""+text).replace(/[^0-9.,]/g, '');
        text = text.replace(',', '.').replace(/,/g,'.').replace(/[.](?!\d*$)/g,'');
        return parseFloat(text);
    } else {
        return 0;
    }
}

export function translate(key) {
    return i18n.t(key);
}

export function isExternalLink(url){
	const domain = process.env.REACT_APP_APP_URL;
    const urlDomain = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/?\n]+)/i)?.[1];
    return urlDomain === domain;
}