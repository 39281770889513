const initialState = {
	openOnboardingModal:false,
	openConfirmConsentModal:false
}

export default function appReducer(state = initialState, action){
	switch(action.type){
		case 'app/setOpenOnboardingModal':
	        return {
				...state,
				openOnboardingModal:action.modal
			};
		case 'app/setOpenConfirmConsentModal':
	        return {
				...state,
				openConfirmConsentModal:action.modal
			};
		default:
			return state;
	}
}