import { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';

export const useAuthToken = () => {
    const [getToken, setAuthToken] = useState(localStorage.getItem('token'));
    const setToken = (authToken) => localStorage.setItem('token', authToken);
    const removeToken = () => localStorage.removeItem('token');

    useEffect(() => {
        setAuthToken(localStorage.getItem('token'));
    });

    return [getToken, setToken, removeToken];
};

export const useLogout = () => {
    const [, , removeToken] = useAuthToken();
    const apolloClient = useApolloClient();

    const logout = async () => {
        await apolloClient.clearStore();
        removeToken();
    };
    return logout;
};