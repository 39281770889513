import { gql } from '@apollo/client';

export const ME_QUERY = gql`
    query MeQuery {
        me {
            id
            name
            customerId
            email
            emailVerified
            profileProgress {
                progress
                data {
                    registered {
                        progress
                        completed
                        count
                        redundant
                    }
                    personalInformation {
                        progress
                        completed
                        count
                        redundant
                    }
                    deliveryAddress {
                        progress
                        completed
                        count
                        redundant
                    }
                    invoiceAddress {
                        progress
                        completed
                        count
                        redundant
                    }
                    billingDetails {
                        progress
                        completed
                        count
                        redundant
                    }
                    companyAddress {
                        progress
                        completed
                        count
                        redundant
                    }
                    meteringPoint {
                        progress
                        completed
                        count
                        redundant
                    }
                    consent {
                        progress
                        completed
                        count
                        redundant
                    }
                }
            }
            roles {
                id
                name
            }
            isAbleTo
            todoList {
                id
                slug
                name
            }
            tags {
                id
                name
                reminderAt
                notes
            }
            tooltips {
                id
                name
                tour {
                    id
                    name
                }
            }
            unreadNotifications
            consentStatus
        }
    }
`;

export const REQUIRED_USER_DATA_QUERY = gql`
    query RequiredUserDataQuery {
        requiredUserData {
            fieldKey
            requestedAt
            reason
        }
    }
`;

export const ADMIN_USERS_TABLE_QUERY = gql`
    query AdminUsersTableQuery($first:Int!, $page:Int!, $filter:AdminUsersFilter, $sortColumn:String, $sortDirection:SortDirection){
        adminUsers(first:$first, page:$page, filter:$filter, sortColumn:$sortColumn, sortDirection:$sortDirection){
            data {
                id
                name
                email
                customerId
                tags {
                    name
                    reminderAt
                    notes
                }
                profileProgress {
                    progress
                }
                createdAt
                lead {
                    id
                    firstname
                }
                initials
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

export const ADMIN_USER_BY_ID_QUERY = gql`
    query AdminUserQuery($id:ID!){
        adminUser(id:$id){
            id
            name
            roles {
                id
            }
            permissions {
                id
            }
        }
    }
`;