import styles from './SiteError.module.scss';
import Badge from '../../Atoms/Badge/Badge';
import ErrorBox from './ErrorBox';

const SiteError = (props) => {
	return (
		<div className={styles.Wrapper}>
			<div className={styles.Box}>
				<ErrorBox {...props} />
			</div>
		</div>
	)
}

export default SiteError;