import { gql, useMutation } from '@apollo/client';
import { useAuthToken, useLogout } from '../../config/auth';

export const LOGIN_MUTATION = gql`
    mutation login($email:String!, $password:String!){
        login(email:$email, password:$password){
            status
            token
        }
    }
`;

export const useLoginMutation = () => {
    const [, setToken] = useAuthToken();
    const [login, { loading, error, data }] = useMutation(LOGIN_MUTATION, {
        onCompleted:(data) => {
            if (data){
                setToken(data.login.token);
            }
        }
    });

    return {
        login:login,
        loading:loading,
        error:error,
        data:data
    }
}

export const LOGOUT_MUTATION = gql`
    mutation logout {
        logout {
            status
            user {
                id
            }
        }
    }
`;

export const useLogoutMutation = () => {
    const removeToken = useLogout();
    const [logout, { loading, error, data }] = useMutation(LOGOUT_MUTATION, {
        onCompleted:(data) => {
            removeToken();
        }
    });

    return {
        logout:logout,
        loading:loading,
        error:error,
        data:data
    }
}

export const REQUEST_PASSWORD_MUTATION = gql`
    mutation requestPassword($email:String!, $returnUrl:String!){
        requestPassword(email:$email, returnUrl:$returnUrl){
            status
        }
    }
`;

export const useRequestPasswordMutation = () => {
    const [requestPassword, { loading, error, data }] = useMutation(REQUEST_PASSWORD_MUTATION, {
        onCompleted:(data) => {
            //
        }
    });

    return {
        requestPassword:requestPassword,
        loading:loading,
        error:error,
        data:data
    }
}

export const RESET_PASSWORD_MUTATION = gql`
    mutation resetPassword($email:String!, $token:String!, $password:String!, $passwordConfirmation:String!){
        resetPassword(email:$email, token:$token, password:$password, passwordConfirmation:$passwordConfirmation){
            status
        }
    }
`;

export const useResetPasswordMutation = () => {
    const [resetPassword, { loading, error, data }] = useMutation(RESET_PASSWORD_MUTATION, {
        onCompleted:(data) => {
            //
        }
    });

    return {
        resetPassword:resetPassword,
        loading:loading,
        error:error,
        data:data
    }
}

export const CREATE_USER_MUTATION = gql`
    mutation createUser($name:String!, $email:String!, $password:String!, $passwordConfirmation:String!, $terms:Boolean!, $returnUrl:String!){
        createUser(name:$name, email:$email, password:$password, passwordConfirmation:$passwordConfirmation, terms:$terms, returnUrl:$returnUrl){
            status
            token
        }
    }
`;

export const useCreateUserMutation = () => {
    const [, setToken] = useAuthToken();
    const [createUser, { loading, error, data }] = useMutation(CREATE_USER_MUTATION, {
        onCompleted:(data) => {
            if (data.createUser){
                setToken(data.createUser.token);
            }
        }
    });

    return {
        createUser:createUser,
        loading:loading,
        error:error,
        data:data
    }
}

export const VERIFY_EMAIL_MUTATION = gql`
    mutation verifyEmail($id:ID!, $hash:String!, $signature:String!, $expires:String!){
        verifyEmail(id:$id, hash:$hash, signature:$signature, expires:$expires){
            status
            user {
                id
            }
        }
    }
`;

export const useVerifyEmailMutation = () => {
    const [verifyEmail, { loading, error, data }] = useMutation(VERIFY_EMAIL_MUTATION, {
        onCompleted:() => {
            //
        }
    });

    return {
        verifyEmail:verifyEmail,
        loading:loading,
        error:error,
        data:data
    }
}

export const ADMIN_LOGIN_USER_MUTATION = gql`
    mutation adminLoginUser($id:ID!){
        adminLoginUser(id:$id){
            status
            token
        }
    }
`;

export const useAdminLoginUserMutation = () => {
    const [, setToken] = useAuthToken();
    const [adminLoginUser, { loading, error, data }] = useMutation(ADMIN_LOGIN_USER_MUTATION, {
        onCompleted:(data) => {
            if (data && data.adminLoginUser){
                setToken(data.adminLoginUser.token);
            }
        }
    });

    return {
        adminLoginUser:adminLoginUser,
        loading:loading,
        error:error,
        data:data
    }
}