const initialState = {
	permissions:[]
}

export default function permissionsReducer(state = initialState, action){
	switch(action.type){
		case 'permissions/setPermissions':
	        return {
				...state,
				permissions:action.permissions
			};
		default:
			return state;
	}
}